// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "BlogsMain-module--aboutMainCircleBlurBlue--nndIy";
export var aboutMainCircleBlurGreen = "BlogsMain-module--aboutMainCircleBlurGreen--Pa18Q";
export var backgroundCircles = "BlogsMain-module--backgroundCircles--DGjbn";
export var backgroundCirclesBlogArticles = "BlogsMain-module--backgroundCirclesBlogArticles--eYCzf";
export var blogImageContainer = "BlogsMain-module--blogImageContainer--S5Wwb";
export var blogMainContainer = "BlogsMain-module--blogMainContainer--b0ZfZ";
export var blogMainContainerWrapper = "BlogsMain-module--blogMainContainerWrapper--E11vC";
export var blogMainTags = "BlogsMain-module--blogMainTags--m0DXD";
export var blogModalNav = "BlogsMain-module--blogModalNav--4-w7B";
export var blogModalWrapper = "BlogsMain-module--blogModalWrapper--IQvZe";
export var blogTag = "BlogsMain-module--blogTag--mgbBO";
export var blogTagsWrapper = "BlogsMain-module--blogTagsWrapper--u-Uh2";
export var blogsCircleBlueRight = "BlogsMain-module--blogsCircleBlueRight--dz2FJ";
export var blogsMainArticles = "BlogsMain-module--blogsMainArticles--m44V3";
export var blogsWrapper = "BlogsMain-module--blogsWrapper--abLz8";
export var contactOutlineCircle = "BlogsMain-module--contactOutlineCircle--Q8T0Q";
export var filterActionButton = "BlogsMain-module--filterActionButton--Bn-Lv";
export var paginationWrapper = "BlogsMain-module--paginationWrapper--XE1jk";
export var stickyContainerLeft = "BlogsMain-module--stickyContainerLeft--yey-M";