// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "ContactForm-module--aboutMainCircleBlurBlue--3+G56";
export var aboutMainCircleBlurGreen = "ContactForm-module--aboutMainCircleBlurGreen--ytnAC";
export var automate = "ContactForm-module--automate--wOIGI";
export var backgroundCircles = "ContactForm-module--backgroundCircles--vtuUO";
export var contactButton = "ContactForm-module--contactButton--dJpmx";
export var contactCheckBox = "ContactForm-module--contactCheckBox--6kgcz";
export var contactConfirmLabel = "ContactForm-module--contactConfirmLabel--37F5T";
export var contactCountry = "ContactForm-module--contactCountry--FddSw";
export var contactEmail = "ContactForm-module--contactEmail--DmBRI";
export var contactFormAddress = "ContactForm-module--contactFormAddress--AEU41";
export var contactFormAddressDiv = "ContactForm-module--contactFormAddressDiv--W6S+b";
export var contactFormAddressItem = "ContactForm-module--contactFormAddressItem--4K93I";
export var contactFormAndAddressContainer = "ContactForm-module--contactFormAndAddressContainer--eISLD";
export var contactFormContainer = "ContactForm-module--contactFormContainer--PzQ+X";
export var contactFormInputs = "ContactForm-module--contactFormInputs--3YaGp";
export var contactFormWrapper = "ContactForm-module--contactFormWrapper--2JOJ3";
export var contactPhoneAndEmail = "ContactForm-module--contactPhoneAndEmail--PUMSH";
export var contactTabsCountry = "ContactForm-module--contactTabsCountry--xbn1N";
export var contactUs = "ContactForm-module--contactUs--SbT6u";
export var contactUsForm = "ContactForm-module--contactUsForm--JjMiW";
export var contactUsHeader = "ContactForm-module--contactUsHeader--9J2ta";
export var head = "ContactForm-module--head--mpoIM";
export var topBanner = "ContactForm-module--topBanner--Cj5U0";